/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  return React.createElement(React.Fragment, null, React.createElement("h2", null, "Właściwości Bloczka BS Wieniec"), "\n", React.createElement("p", null, "Bloczki BS Wieniec mają wysokość konstrukcyjną równą połowie standardowego modułu systemu ściennego Czamaninek. Połączenie ich z bloczkami Liatop Start tworzy jednorodną ścianę fundamentową o stałych parametrach. Wytrzymałość na poziomie 15 MPa pozwala na konstruowanie budynków mieszkalnych jednorodzinnych bez żadnych ograniczeń, a także w budynkach mieszkalnych wielorodzinnych, obiektach użyteczności publicznej czy niskich biurowcach. Bloczek fundamentowy uzupełniający zapewnia niewrażliwość na podciąganie kapilarne, co zapobiega zatrzymywaniu wody i utrzymuje deklarowane właściwości niezależnie od warunków gruntowo-wodnych."), "\n", React.createElement("p", null, "Dane Techniczne Bloczka BS Wieniec"), "\n", React.createElement("table", {
    class: "table-section align-left"
  }, React.createElement("tr", null, React.createElement("th", {
    colspan: "2"
  }, "Bloczek BS Wieniec")), React.createElement("tr", null, React.createElement("td", null, "Wymiary"), React.createElement("td", null, "Długość: 125, 250, 375 mm ", React.createElement("br"), " Szerokość: 240 mm", React.createElement("br"), " Wysokość: 120 mm", React.createElement("br"), "Kategoria odchyłek wymiarów: D1")), React.createElement("tr", null, React.createElement("td", null, "Masa"), React.createElement("td", null, "14 kg")), React.createElement("tr", null, React.createElement("td", null, "Klasa wytrzymałości"), React.createElement("td", null, "15 N/mm", React.createElement("sup", null, "2"))), React.createElement("tr", null, React.createElement("td", null, "Grupa elementu"), React.createElement("td", null, "1")), React.createElement("tr", null, React.createElement("td", null, "Współczynnik przewodzenia ciepła ", React.createElement("i", null, "I")), React.createElement("td", null, "0.830 ", React.createElement("i", null, "W/m*K"))), React.createElement("tr", null, React.createElement("td", null, "Opór cieplny ", React.createElement("i", null, "R")), React.createElement("td", null, "0.289 ", React.createElement("i", null, "m", React.createElement("sup", null, "2"), "/K*W"))), React.createElement("tr", null, React.createElement("td", null, "Współczynnik przenikania ciepła ", React.createElement("i", null, "U")), React.createElement("td", null, "2.178 ", React.createElement("i", null, "W/m", React.createElement("sup", null, "2"), "*K")))), "\n", React.createElement("p", null, "Bloczki fundamentowe uzupełniające BS wieniec posiadają certyfikat CE i podlegają stałej kontroli właściwości podczas produkcji. Deklarowane parametry bloczków zostały przedstawione w tabeli. Zachęcamy do zapoznania się z ", React.createElement("a", {
    href: "https://czamaninek.pl/produkty",
    target: "_new"
  }, "kartą techniczną"), " oraz ", React.createElement("a", {
    href: "https://czamaninek.pl/produkty",
    target: "_new"
  }, "katalogiem naszych pustaków i bloczków keramzytobetonowych"), ". Projektantów zapraszamy do kontaktu – udostępnimy niezbędne dane i służymy pomocą w procesie projektowania z elementów na bazie keramzytu i keramzytobetonu."), "\n", React.createElement("h2", null, "Zastosowanie Bloczka Fundamentowego Uzupełniającego"), "\n", React.createElement("p", null, "Bloczki BS wieniec umożliwiają murowanie przy pomocy dedykowanego kleju na pióro i wpust. Dzięki uzupełnieniu o elementy o mniejszej długości (tzw. połówki i ćwiartki) zminimalizowana jest konieczność docinania bloczków. Bloczek fundamentowy uzupełniający doskonale sprawdza się w budownictwie jednorodzinnym, wielorodzinnym oraz obiektach użyteczności publicznej."), "\n", React.createElement("h3", null, "Poznaj Nasze Produkty"), "\n", React.createElement("ul", null, React.createElement("li", null, React.createElement("a", {
    href: "https://czamaninek.pl/",
    target: "_new"
  }, "Producent materiałów budowlanych")), React.createElement("li", null, React.createElement("a", {
    href: "https://czamaninek.pl/produkty/"
  }, "Materiały budowlane")), React.createElement("li", null, React.createElement("a", {
    href: "https://czamaninek.pl/produkty/system-scienny/"
  }, "Pustaki ścienne")), React.createElement("li", null, React.createElement("a", {
    href: "https://czamaninek.pl/produkty/stropy/",
    target: "_new"
  }, "Stropy")), React.createElement("li", null, React.createElement("a", {
    href: "https://czamaninek.pl/produkty/system-fundamentowy/",
    target: "_new"
  }, "Bloczki fundamentowe"))), "\n", React.createElement("p", null, "Bloczek BS Wieniec i bloczek fundamentowy uzupełniający to elementy, które w połączeniu z bloczkami Liatop Start tworzą jednorodną i wytrzymałą ścianę fundamentową. Dzięki swoim właściwościom i certyfikatom jakości, są idealnym wyborem do budowy solidnych i trwałych fundamentów."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
